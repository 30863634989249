import "./src/styles/fonts.css";
import "./src/styles/global.css";

export const onInitialClientRender = () => {
  if (typeof window !== "undefined") {
    (function (w, d) {
      if (!w.rdt) {
        const p = (w.rdt = function () {
          if (p.sendEvent) {
            p.sendEvent.apply(p, arguments);
          } else {
            p.callQueue.push(arguments);
          }
        });
        p.callQueue = [];
        const t = d.createElement("script");
        t.src = "https://www.redditstatic.com/ads/pixel.js";
        t.async = true;
        const s = d.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(t, s);
      }
    })(window, document);

    window.rdt("init", "a2_gbweu16jig4z");
    window.rdt("track", "PageVisit");
  }
  // Get the user's browser language
  const userLanguage =
    window.navigator.language || window.navigator.userLanguage;

  // Use a mapping to match browser language to supported languages

  const currentPath = window.location.pathname;
  // Set the language to the detected language or fallback to the default
  const selectedLanguage = userLanguage?.slice(0, 2) || "en";
  // Redirect to the appropriate language-specific homepage
  if (selectedLanguage === "fr" && !currentPath.startsWith(`/${"fr"}/`)) {
    window.location.href = `/fr${currentPath}`;
  }
};
onInitialClientRender();
